import React from 'react';

interface CharacterTitleProps {
  name: string;
  query: string;
}

const CharacterTitle: React.FC<CharacterTitleProps> = ({ name, query }) => {
  if (!query) {
    return <span>{name}</span>;
  }

  const regex = new RegExp(`(${query})`, 'gi');
  const parts = name.split(regex);

  return (
    <>
      {parts.map((part, index) => (regex.test(part) ? <strong key={index}>{part}</strong> : part))}
    </>
  );
};

export default CharacterTitle;
