import React from 'react';
import './CharacterCard.scss';
import CharacterTitle from './CharacterTitle';
import { Character } from "../../shared/types/character";

interface CharacterCardProps {
  character: Character;
  checked: boolean;
  selected: boolean;
  query: string;
  onChange: (character: Character) => void;
  onMouseEnter: () => void;
}

const CharacterCard: React.FC<CharacterCardProps> = props => {
  const { character, onChange, checked, query, selected, onMouseEnter } = props;

  return (
    <label
      key={character.id}
      className={`character-card ${selected ? 'character-card-selected' : ''}`}
      onMouseEnter={onMouseEnter}
    >
      <input
        className="character-card__checkbox"
        type="checkbox"
        checked={checked}
        onChange={() => onChange(character)}
      />
      <img className="character-card__image" src={character.image} alt={character.name} />
      <div className="character-card__content">
        <div className="character-card__content__title">
          <CharacterTitle name={character.name} query={query} />
        </div>
        <div className="character-card__content__episode-count">
          {character?.episode?.length} Episodes
        </div>
      </div>
    </label>
  );
};

export default CharacterCard;
