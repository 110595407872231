import React, { useState, useEffect, useRef, KeyboardEvent, ChangeEvent } from 'react';
import './MultiSelect.scss';
import { useQuery } from '@apollo/client';
import { GET_CHARACTERS } from '../../queries';
import { CharacterCard } from '../CharacterCard';
import { Character } from '../../shared/types/character';
import { Down, Remove } from '../Icons';

const MultiSelect: React.FC = () => {
  const [query, setQuery] = useState<string>('');

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [filteredCharacters, setFilteredCharacters] = useState<Character[]>([]);

  const [selectedCharacters, setSelectedCharacters] = useState<Character[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { loading, data, error } = useQuery(GET_CHARACTERS, { variables: { name: query } });

  useEffect(() => {
    setFilteredCharacters(data?.characters?.results || []);
  }, [data]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex(prevIndex =>
        prevIndex < filteredCharacters.length - 1 ? prevIndex + 1 : prevIndex,
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (event.key === 'Enter') {
      event.preventDefault();
      selectedIndex > -1 && handleCharacterSelection(filteredCharacters[selectedIndex]);
    } else if (event.key === 'Backspace') {
      if (!query.length && filteredCharacters.length > 0) {
        event.preventDefault();
        event.stopPropagation();
        const newArray = [...selectedCharacters];
        newArray.pop();
        setSelectedCharacters(newArray);
      }
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e?.target?.value);
    setShowOptions(true);
    if (inputRef?.current instanceof HTMLInputElement) {
      inputRef.current.style.width = `${inputRef?.current?.scrollWidth}px`;
    }
  };

  const handleCharacterSelection = (character: Character) => {
    if (selectedCharacters.find(item => item.id === character.id)) {
      setSelectedCharacters(prevSelected => prevSelected.filter(item => item.id !== character.id));
    } else {
      setSelectedCharacters(prevSelected => [...prevSelected, character]);
    }
    inputRef.current instanceof HTMLInputElement && inputRef.current?.focus();
  };

  const handleRemoveCharacter = (
    e: React.MouseEvent<SVGSVGElement>,
    character: Character,
  ): void => {
    e?.stopPropagation?.();
    const updatedSelection = selectedCharacters?.filter(
      selectedCharacter => selectedCharacter.id !== character.id,
    );
    setSelectedCharacters(updatedSelection);
  };

  return (
    <div className="multiselect">
      <div
        className="multiselect__selected-items"
        onClick={() => {
          setShowOptions(prevState => !prevState);
          inputRef.current instanceof HTMLInputElement && inputRef?.current?.focus();
        }}
      >
        {selectedCharacters.map(item => (
          <div key={item.id} className="multiselect__selected-item">
            {item.name}
            <Remove
              className="multiselect__remove-icon"
              onClick={e => handleRemoveCharacter(e, item)}
            />
          </div>
        ))}
        <div className="multiselect__search-container">
          <input
            ref={inputRef}
            type="text"
            value={query}
            placeholder={!selectedCharacters.length ? 'Search...' : ''}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
          />
        </div>
        <Down className="multiselect__down-icon" />
      </div>
      {showOptions && (
        <div className="multiselect__character-list">
          {loading && <div className="multiselect__character-list__empty-state">Loading...</div>}
          {error && (
            <div className="multiselect__character-list__empty-state">Error fetching data...</div>
          )}
          {!loading && !filteredCharacters?.length && query && (
            <div className="multiselect__character-list__empty-state">No results found.</div>
          )}
          {!loading &&
            filteredCharacters.map((option, index) => (
              <CharacterCard
                key={option.id}
                character={option}
                onChange={handleCharacterSelection}
                selected={index === selectedIndex}
                checked={selectedCharacters.some(item => item.id === option.id)}
                query={query}
                onMouseEnter={() => setSelectedIndex(index)}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
