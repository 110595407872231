import React from 'react';
import './App.scss';
import { MultiSelect } from './components/MultiSelect';

function App() {
  return (
    <div className="container">
      <MultiSelect />
    </div>
  );
}

export default App;
